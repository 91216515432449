import React, { useState, useEffect, useContext } from 'react';
import { formatDistance } from 'date-fns';
import de from 'date-fns/locale/de';
import classNames from 'classnames';

import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import { ROLE_TRAINER } from 'shared/constants/global';
import Skeleton from 'components/skeleton';
import SearchBox from 'components/SearchBox';

export interface Message {
  id: string;
  userId: string;
  userName: string;
  content: string;
  type: 'text' | 'image' | 'audio' | 'file' | 'application' | 'video';
  fileUrl?: string;
  fileName?: string;
  createdAt: firebase.firestore.Timestamp;
  read: boolean;
  userRole?: number;
}

interface Chat {
  id: string;
  messages: Message[];
  users: string[];
  userNames: string[];
  lastMessage: string;
  lastMessageAt: firebase.firestore.Timestamp;
  chatPartner: string;
  trainerName: string | null;
  lastMessageRead: boolean;
  unreadMessages: number;
  createdAt: firebase.firestore.Timestamp;
  unsubscribeSub?: () => void;
  isPublic: boolean;
}

interface SidebarProps {
  onChatSelect: (chat: SelectedChat) => void;
}

export type SelectedChat = {
  id: string;
  messageId?: string;
  searchTerm?: string;
  activeTab?: 'own' | 'public';
};

interface SearchResult {
  message: Message;
  chatId: string;
  chatPartner: string;
}

export default function Sidebar({ onChatSelect }: SidebarProps) {
  const [chats, setChats] = useState<Chat[]>([]);
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const { tenant, user, userData } = useContext(AuthContext);
  const [filterInput, setFilterInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [debouncedFilter, setDebouncedFilter] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [activeTab, setActiveTab] = useState<'own' | 'public'>('own');
  const [userShareChats, setUserShareChats] = useState<{ [key: string]: boolean }>({});

  const db = firebase.firestore();

  const getChatPartnerNames = async (chatData: any, currentTab: 'own' | 'public') => {
    if (currentTab === 'public') {
      const otherUserIds = chatData?.users.filter((u: string) => u !== user?.uid);
      const names = await Promise.all(
        otherUserIds.map(async (userId: string) => {
          const otherUserDoc = await db.collection(`tenants/${tenant}/users`).doc(userId).get();
          return otherUserDoc.data()?.fullName;
        })
      );
      return { memberName: names[0], trainerName: names[1] };
    }
    const otherUserId = chatData?.users.find((u: any) => u !== user?.uid);
    const otherUserDoc = await db.collection(`tenants/${tenant}/users`).doc(otherUserId).get();
    return { memberName: otherUserDoc.data()?.fullName, trainerName: null };
  };

  // Fetch shareChats status for all users in chats
  useEffect(() => {
    const fetchUserShareChats = async () => {
      const userIds = new Set<string>();
      chats.forEach(chat => {
        chat.users.forEach(userId => userIds.add(userId));
      });

      const shareChatsPromises = Array.from(userIds).map(async userId => {
        const userDoc = await db.collection(`tenants/${tenant}/users`).doc(userId).get();
        return { userId, shareChats: userDoc.data()?.shareChats || false };
      });

      const results = await Promise.all(shareChatsPromises);
      const shareChatsMap = results.reduce((acc, { userId, shareChats }) => {
        acc[userId] = shareChats;
        return acc;
      }, {} as { [key: string]: boolean });

      setUserShareChats(shareChatsMap);
    };

    if (chats.length > 0) {
      fetchUserShareChats();
    }
  }, [chats, tenant]);

  useEffect(() => {
    const chatsMap = new Map();

    const unsubscribe = db
      .collection(`tenants/${tenant}/chats`)
      .where('users', 'array-contains', user?.uid)
      .onSnapshot(snapshot => {
        const newChats = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Chat[];

        if (newChats.length === 0) {
          setIsLoading(false);
        }

        newChats.forEach(chat => {
          if (!chatsMap.has(chat.id)) {
            chatsMap.set(chat.id, chat);

            const unsubscribeSub = db
              .collection(`tenants/${tenant}/chats/${chat.id}/messages`)
              .orderBy('createdAt', 'desc')
              .onSnapshot(snapshotSub => {
                const messagesData = snapshotSub.docs.map(doc => doc.data());

                db.collection(`tenants/${tenant}/chats`)
                  .doc(chat.id)
                  .get()
                  .then(async doc => {
                    if (doc.exists) {
                      const chatData = doc.data();
                      const chatPartnerName = await getChatPartnerNames(chatData, activeTab);

                      const lastMessage = messagesData.sort(
                        (a, b) => a.createdAt?.toDate().getTime() - b.createdAt?.toDate().getTime()
                      )[messagesData.length - 1];

                      const unreadMessages = messagesData.filter(
                        message => message.userId !== user?.uid && !message.read
                      );

                      const updatedChat = {
                        ...chat,
                        lastMessage: lastMessage?.content ?? '',
                        chatPartner: chatPartnerName.memberName,
                        trainerName: chatPartnerName.trainerName,
                        lastMessageAt: lastMessage?.createdAt ?? firebase.firestore.Timestamp.now(),
                        lastMessageRead: unreadMessages.length === 0,
                        unreadMessages: unreadMessages.length,
                      };

                      chatsMap.set(chat.id, updatedChat);
                      setChats(Array.from(chatsMap.values()));
                      setIsLoading(false);
                    }
                  });
              });

            // eslint-disable-next-line no-param-reassign
            chat.unsubscribeSub = unsubscribeSub;
          }
        });
      });

    // Subscribe to chats from users who share their chats
    let sharedChatsUnsubscribe: (() => void) | undefined;
    if (userData?.role === ROLE_TRAINER) {
      sharedChatsUnsubscribe = db
        .collection(`tenants/${tenant}/chats`)
        .where('users', 'not-in', [[user?.uid]])
        .onSnapshot(snapshot => {
          const sharedChats = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          })) as Chat[];

          sharedChats.forEach(chat => {
            if (!chatsMap.has(chat.id)) {
              chatsMap.set(chat.id, chat);

              const unsubscribeSub = db
                .collection(`tenants/${tenant}/chats/${chat.id}/messages`)
                .orderBy('createdAt', 'desc')
                .onSnapshot(snapshotSub => {
                  const messagesData = snapshotSub.docs.map(doc => doc.data());

                  db.collection(`tenants/${tenant}/chats`)
                    .doc(chat.id)
                    .get()
                    .then(async doc => {
                      if (doc.exists) {
                        const chatData = doc.data();
                        const chatPartnerName = await getChatPartnerNames(chatData, activeTab);

                        const lastMessage = messagesData.sort(
                          (a, b) => a.createdAt?.toDate().getTime() - b.createdAt?.toDate().getTime()
                        )[messagesData.length - 1];

                        const unreadMessages = messagesData.filter(
                          message => message.userId !== user?.uid && !message.read
                        );

                        const updatedChat = {
                          ...chat,
                          lastMessage: lastMessage?.content ?? '',
                          chatPartner: chatPartnerName.memberName,
                          trainerName: chatPartnerName.trainerName,
                          lastMessageAt: lastMessage?.createdAt ?? firebase.firestore.Timestamp.now(),
                          lastMessageRead: unreadMessages.length === 0,
                          unreadMessages: unreadMessages.length,
                        };

                        chatsMap.set(chat.id, updatedChat);
                        setChats(Array.from(chatsMap.values()));
                      }
                    });
                });

              // eslint-disable-next-line no-param-reassign
              chat.unsubscribeSub = unsubscribeSub;
            }
          });
        });
    }

    return () => {
      unsubscribe();
      if (sharedChatsUnsubscribe) {
        sharedChatsUnsubscribe();
      }
      chatsMap.forEach(chat => chat.unsubscribeSub && chat.unsubscribeSub());
    };
  }, [tenant, user?.uid, userData?.role, activeTab]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedFilter(filterInput);
      if (filterInput.trim()) {
        setIsSearching(true);
        searchMessages(filterInput.trim());
      } else {
        setIsSearching(false);
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [filterInput]);

  const searchMessages = async (searchTerm: string) => {
    try {
      const chatsSnapshot = await db
        .collection(`tenants/${tenant}/chats`)
        .where('users', 'array-contains', user?.uid)
        .get();

      const searchPromises = chatsSnapshot.docs.map(async chatDoc => {
        const chatData = chatDoc.data();
        const chatPartnerId = chatData.users.find((u: string) => u !== user?.uid);
        const chatPartnerDoc = await db.collection(`tenants/${tenant}/users`).doc(chatPartnerId).get();
        const chatPartnerName = await getChatPartnerNames(chatData, activeTab);

        const messagesSnapshot = await db
          .collection(`tenants/${tenant}/chats/${chatDoc.id}/messages`)
          .where('content', '>=', searchTerm)
          .where('content', '<=', `${searchTerm}\uf8ff`)
          .get();

        return messagesSnapshot.docs.map(messageDoc => ({
          message: { id: messageDoc.id, ...messageDoc.data() } as Message,
          chatId: chatDoc.id,
          chatPartner: chatPartnerName.memberName,
        }));
      });

      const results = await Promise.all(searchPromises);
      setSearchResults(results.flat() as SearchResult[]);
    } catch (error) {
      console.error('Error searching messages:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterInput(e.target.value);
  };

  const filteredChats = chats
    .filter(chat => {
      const searchTerm = debouncedFilter.toLowerCase();
      const chatPartner = chat?.chatPartner?.toLowerCase() || '';
      const lastMessage = chat?.lastMessage?.toLowerCase() || '';
      const matchesSearch = chatPartner.includes(searchTerm) || lastMessage.includes(searchTerm);
      const currentUserId = user?.uid || '';

      if (userData?.role === ROLE_TRAINER) {
        if (activeTab === 'own') {
          return matchesSearch && chat.users.includes(currentUserId);
        }
        // For public tab, check if any user in the chat has shareChats enabled
        return (
          matchesSearch && !chat.users.includes(currentUserId) && chat.users.some(userId => userShareChats[userId])
        );
      }

      return matchesSearch;
    })
    .sort((a, b) => {
      const dateA = a.lastMessageAt?.toDate() || new Date(0);
      const dateB = b.lastMessageAt?.toDate() || new Date(0);
      return dateB.getTime() - dateA.getTime();
    });

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col gap-y-20">
          <Skeleton className="w-full h-70 opacity-50" translucent />
          <Skeleton className="w-full h-70 opacity-50" translucent />
        </div>
      );
    }

    if (isSearching) {
      return (
        <div className="flex flex-col">
          <div className="flex items-center justify-between mb-4">
            <span className="text-sm text-textColor opacity-50">Suchergebnisse</span>
          </div>
          {searchResults.length === 0 ? (
            <div className="text-sm text-textColor opacity-50 text-center py-4">Keine Ergebnisse gefunden</div>
          ) : (
            searchResults.map((result, index) => (
              <button
                key={`${result.chatId}-${result.message.id}-${index}`}
                className="hover:bg-secondaryBgColor outline-none text-left p-4"
                onClick={() => {
                  onChatSelect({
                    id: result.chatId,
                    messageId: result.message.id,
                    searchTerm: filterInput.trim(),
                    activeTab,
                  });
                }}
                type="button"
              >
                <div className="flex flex-col gap-1">
                  <p className="text-sm font-medium">{result.chatPartner}</p>
                  <p className="text-sm opacity-50">{result.message.content}</p>
                  <p className="text-xs opacity-30">
                    {formatDistance(result.message.createdAt.toDate(), new Date(), {
                      addSuffix: true,
                      locale: de,
                    })}
                  </p>
                </div>
              </button>
            ))
          )}
        </div>
      );
    }

    return filteredChats.map(chat => (
      <button
        key={chat?.id}
        className={classNames('hover:bg-secondaryBgColor outline-none', {
          'bg-secondaryBgColor': selectedChat === chat?.id,
        })}
        onClick={() => {
          setSelectedChat(chat?.id || null);
          onChatSelect({
            id: chat?.id || '',
            searchTerm: debouncedFilter.trim(),
            activeTab,
          });
        }}
        type="button"
      >
        <div className="flex items-center p-4 gap-x-10">
          <div className="overflow-hidden flex-grow">
            <p className="text-sm font-medium truncate text-left">{chat?.chatPartner || ''}</p>
            {chat?.trainerName && <p className="text-12 opacity-50 truncate text-left">{chat.trainerName}</p>}
            <p className="text-sm truncate text-left opacity-50">{chat?.lastMessage || ''}</p>
          </div>
          <div
            className={`flex flex-col items-center gap-x-10 opacity-50 ${
              !chat?.lastMessageRead && chat?.chatPartner !== user?.displayName && activeTab === 'own'
                ? 'text-accentColor opacity-100'
                : ''
            }`}
          >
            {chat?.lastMessageAt && (
              <div className="flex-shrink-0">
                <p className="text-sm font-medium truncate">
                  {formatDistance(chat?.lastMessageAt?.toDate(), new Date(), {
                    addSuffix: true,
                    locale: de,
                  })}
                </p>
              </div>
            )}
            {chat?.unreadMessages > 0 && activeTab === 'own' && (
              <div className="w-20 h-20 rounded-full bg-accentColor text-textColor text-xs flex justify-center items-center self-end">
                {chat?.unreadMessages}
              </div>
            )}
          </div>
        </div>
      </button>
    ));
  };

  return (
    <div className="overflow-y-auto">
      <div className="flex flex-col">
        <SearchBox searchValue={filterInput} onChange={handleInputChange} className="mb-20" inputType="search" />
        {userData?.role === ROLE_TRAINER && !isSearching && (
          <div className="flex border-b border-secondaryBgColor mb-4">
            <button
              onClick={() => setActiveTab('own')}
              className={classNames(
                'flex-1 py-2 text-sm font-medium outline-none',
                activeTab === 'own'
                  ? 'text-accentColor border-b-2 border-accentColor'
                  : 'text-textColor opacity-50 hover:opacity-100'
              )}
              type="button"
            >
              Eigene Nachrichten
            </button>
            <button
              onClick={() => setActiveTab('public')}
              className={classNames(
                'flex-1 py-2 text-sm font-medium outline-none',
                activeTab === 'public'
                  ? 'text-accentColor border-b-2 border-accentColor'
                  : 'text-textColor opacity-50 hover:opacity-100'
              )}
              type="button"
            >
              andere Trainer
            </button>
          </div>
        )}
        {renderContent()}
      </div>
    </div>
  );
}
