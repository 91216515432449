import React, { useState, useEffect, useRef, useContext } from 'react';
import { MicrophoneIcon, PaperAirplaneIcon, PaperClipIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import { ROLE_MEMBER, ROLE_TRAINER } from 'shared/constants/global';
import Modal from 'pages/Surveys/components/Modal';
import TransitionContainer from 'components/TransitionContainer';
import Headline from 'components/Headline';
import Button from 'components/Button';
import { getIsWebView, sanitizeFilename } from 'shared/functions/global';
import ChatSidebar, { SelectedChat, Message } from './ChatSidebar';
import UserConsentForChat from './UserConsentForChat';
import CreateChat from './CreateChat';

interface GroupedMessages {
  [date: string]: Message[];
}

export default function Chat() {
  const { user, userData, tenant, setShowMobileNav } = useContext(AuthContext);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const recordingTimerRef = useRef<NodeJS.Timeout>();
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const mediaRecorderRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSendingAudio, setIsSendingAudio] = useState(false);
  const audioChunksRef = useRef<Blob[]>([]);
  const [selectedChat, setSelectedChat] = useState<SelectedChat | null>(null);
  const [isCreatingChat, setIsCreatingChat] = useState(false);
  const [chatPartner, setChatPartner] = useState<{ name: string; id: string; otherUserName?: string } | null>(null);
  const history = useHistory();
  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);
  const messageRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isTextareaExpanded, setIsTextareaExpanded] = useState(false);

  const db = firebase.firestore();
  const storage = firebase.storage();

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      setIsTextareaExpanded(textarea.scrollHeight > 40); // 40px is approximately the height of one line
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  useEffect(() => {
    const unsubscribe = db
      .collection(`tenants/${tenant}/chats/${selectedChat?.id}/messages`)
      .orderBy('createdAt', 'asc')
      .limit(50)
      .onSnapshot(snapshot => {
        const newMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Message[];
        setMessages(newMessages);

        // Mark messages as read
        newMessages.forEach(message => {
          if (message.userId !== user?.uid && !message.read) {
            db.collection(`tenants/${tenant}/chats/${selectedChat?.id}/messages`)
              .doc(message.id)
              .update({ read: true });
          }
        });

        // Scroll to selected message if it exists
        if (selectedMessageId) {
          setTimeout(() => {
            const messageElement = messageRefs.current[selectedMessageId];
            if (messageElement) {
              messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }, 100);
          setSelectedMessageId(null);
        } else if (selectedChat) {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      });

    return () => unsubscribe();
  }, [selectedChat, selectedMessageId]);

  useEffect(() => {
    if (selectedChat) {
      db.collection(`tenants/${tenant}/chats`)
        .doc(selectedChat?.id)
        .get()
        .then(async doc => {
          if (doc.exists) {
            const chatData = doc.data();
            if (selectedChat?.activeTab === 'public') {
              const otherUserIds = chatData?.users.filter((u: string) => u !== user?.uid);
              const names = await Promise.all(
                otherUserIds.map(async (userId: string) => {
                  const otherUserDoc = await db.collection(`tenants/${tenant}/users`).doc(userId).get();
                  return otherUserDoc.data()?.fullName;
                })
              );
              setChatPartner({ name: names.join(' & '), id: otherUserIds[0] });
            } else {
              const otherUserId = chatData?.users.find((u: any) => u !== user?.uid);
              const ref = db.collection(`tenants/${tenant}/users`).doc(otherUserId);
              ref.get().then(userDoc => {
                if (userDoc.exists) {
                  const chatPartnerData = userDoc.data();
                  setChatPartner({ name: chatPartnerData?.fullName, id: userDoc?.id });
                }
              });
            }
          }
        });
    } else {
      window.scrollTo(0, 0);
    }
  }, [selectedChat]);

  useEffect(() => {
    if (selectedChat) {
      setShowMobileNav(false);
    } else {
      setShowMobileNav(true);
    }
  }, [selectedChat]);

  const sendMessage = async (
    content: string,
    type = 'text',
    fileUrl: string | null = null,
    fileName: string | null = null
  ) => {
    await db.collection(`tenants/${tenant}/chats/${selectedChat?.id}/messages`).add({
      userId: user?.uid,
      content,
      userName: userData?.fullName,
      type,
      fileUrl,
      fileName,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      read: false,
      userRole: Number(userData?.role),
    });
    setInput('');
    setAudioBlob(null);
  };

  const handleSend = () => {
    if (input.trim()) {
      sendMessage(input);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const clearFileName = sanitizeFilename(file?.name || '');
    if (file && clearFileName) {
      setIsUploading(true);
      const storageRef = storage.ref(`tenants/${tenant}/chats/${selectedChat?.id}/documents/${clearFileName}`);
      await storageRef.put(file);
      const downloadURL = await storageRef.getDownloadURL();
      const fileType = file.type.split('/')[0];
      sendMessage(clearFileName, fileType, downloadURL, clearFileName);
      setIsUploading(false);
    }
  };

  const startRecording = () => {
    try {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          // @ts-ignore
          mediaRecorderRef.current = new MediaRecorder(stream, {
            mimeType: 'audio/mp4;codecs=mp4a.40.2', // Use AAC codec for better iOS compatibility
          });
          // @ts-ignore
          mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
          // @ts-ignore
          mediaRecorderRef.current.start();
          setIsRecording(true);
          setRecordingDuration(0);
          recordingTimerRef.current = setInterval(() => {
            setRecordingDuration(prev => prev + 1);
          }, 1000);
        })
        .catch(error => {
          setIsRecording(false);
          console.error('Error accessing microphone:', error);
        });
    } catch (error) {
      console.error('Error starting recording:', error);
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      try {
        // @ts-ignore
        mediaRecorderRef.current.removeEventListener('dataavailable', handleDataAvailable);
        // @ts-ignore
        mediaRecorderRef.current.stop();
        // @ts-ignore
        const tracks = mediaRecorderRef.current.stream.getTracks();
        tracks?.forEach((track: any) => track.stop());
        setIsRecording(false);
        if (recordingTimerRef.current) {
          clearInterval(recordingTimerRef.current);
        }
        setRecordingDuration(0);
        setIsSendingAudio(true);
        // @ts-ignore
        mediaRecorderRef.current = null;
      } catch (error) {
        console.error('Error stopping recording:', error);
        setIsRecording(false);
      }
    }
  };

  const sendAudioMessage = async () => {
    if (audioBlob) {
      try {
        setIsUploading(true);
        const storageRef = storage.ref(`tenants/${tenant}/chats/${selectedChat?.id}/documents/${Date.now()}.mp4`);
        await storageRef.put(audioBlob);
        const downloadURL = await storageRef.getDownloadURL();
        sendMessage('Sprachnachricht', 'audio', downloadURL, `${Date.now()}.mp4`);
        setIsUploading(false);
        setIsSendingAudio(false);
        setAudioBlob(null);
      } catch (error) {
        console.error('Error sending audio message:', error);
        setIsUploading(false);
        setIsSendingAudio(false);
      }
    }
  };

  useEffect(() => {
    if (isSendingAudio && !isRecording && audioBlob) {
      sendAudioMessage();
    }
  }, [isSendingAudio, isRecording, audioBlob]);

  const handleDataAvailable = (event: any) => {
    if (event.data.size > 0) {
      if (audioBlob) {
        setAudioBlob(null);
        setIsSendingAudio(false);
      }
      const blob = new Blob([event.data], { type: 'audio/mp4' });
      setAudioBlob(blob);
    } else {
      setAudioBlob(null);
      setIsRecording(false);
      setIsUploading(false);
    }
  };

  const groupMessagesByDate = (chatMessages: Message[]): GroupedMessages => {
    return chatMessages.reduce((groups, message) => {
      const date = new Date(message.createdAt?.toDate() || new Date()).toLocaleDateString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      return {
        ...groups,
        [date]: [...(groups[date] || []), message],
      };
    }, {} as GroupedMessages);
  };

  const handleChatSelect = (chat: SelectedChat) => {
    setSelectedChat(chat);
    if (chat.messageId) {
      setSelectedMessageId(chat.messageId);
    }
  };

  const highlightText = (text: string, searchTerm: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return text.split(regex).map((part, i) =>
      regex.test(part) ? (
        <span key={i} className="bg-accentColor bg-opacity-30 rounded px-1">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const renderMessage = (message: Message) => {
    const isCurrentUser = message.userId === user?.uid;
    const isTrainer = message.userRole === ROLE_TRAINER;
    const messageTime = new Date(message.createdAt?.toDate() || new Date()).toLocaleTimeString('de-DE', {
      hour: 'numeric',
      minute: 'numeric',
    });

    const setMessageRef = (el: HTMLDivElement | null) => {
      messageRefs.current[message.id] = el;
    };

    return (
      <div
        key={message.id}
        ref={setMessageRef}
        className={`mb-4 ${
          isCurrentUser || (isTrainer && selectedChat?.activeTab === 'public') ? 'text-right' : 'text-left'
        } ${message.id === selectedMessageId ? 'bg-secondaryBgColor' : ''}`}
      >
        <div
          className={`inline-block p-2 rounded-lg relative group ${
            isCurrentUser || (isTrainer && selectedChat?.activeTab === 'public')
              ? 'bg-accentColor text-buttonTextColor'
              : 'bg-secondaryBgColor text-textColor'
          }`}
        >
          {selectedChat?.activeTab === 'public' && (
            <p className="text-xs font-semibold mb-1 opacity-50">{message.userName}</p>
          )}
          {renderMessageContent(message)}
          <div
            className={classNames('flex items-center mt-1', {
              'justify-start': !isCurrentUser,
              'justify-end': isCurrentUser,
            })}
          >
            {isCurrentUser && (
              <button
                onClick={() => deleteMessage(message.id)}
                className="opacity-0 mr-20 text-xs group-hover:opacity-100 transition-opacity"
                type="button"
              >
                Löschen
              </button>
            )}
            <p className="text-xs opacity-50">{messageTime}</p>
          </div>
        </div>
      </div>
    );
  };

  const renderMessageContent = (message: Message) => {
    switch (message.type) {
      case 'text':
        return (
          <p style={{ whiteSpace: 'pre-wrap' }}>{highlightText(message.content, selectedChat?.searchTerm || '')}</p>
        );
      case 'image':
        return (
          <a
            href={message.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => {
              e.preventDefault();
              setSelectedImage(message.fileUrl || null);
            }}
          >
            <img
              src={message.fileUrl || '/placeholder.svg'}
              alt={message.fileName}
              className="rounded-lg max-w-200 h-200 object-cover cursor-pointer"
            />
          </a>
        );
      case 'audio':
        return (
          <div className="flex items-center">
            <audio controls className="w-300" preload="metadata" playsInline>
              <source src={message.fileUrl} type="audio/mp4" />
              <source src={message.fileUrl} type="audio/mpeg" />
              <source src={message.fileUrl} type="audio/webm" />
              <track kind="captions" label="Sprachnachricht" src="" />
              Ihr Browser unterstützt das Audio-Element nicht.
            </audio>
          </div>
        );
      case 'application':
        return (
          <a
            href={message.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 hover:underline"
          >
            <PaperClipIcon className="h-4 w-4" />
            <span>{highlightText(message.fileName || '', selectedChat?.searchTerm || '')}</span>
          </a>
        );
      case 'video':
        return (
          <video src={message.fileUrl} controls className="max-w-200 h-200">
            <track kind="captions" label="Videonachricht" src="" />
          </video>
        );
      default:
        return (
          <a
            href={message.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 hover:underline"
          >
            <PaperClipIcon className="h-4 w-4" />
            <span>{highlightText(message.fileName || '', selectedChat?.searchTerm || '')}</span>
          </a>
        );
    }
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && !getIsWebView()) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleOpenDocuments = () => {
    setShowMobileNav(true);
    if (userData?.role === ROLE_TRAINER) {
      history.push(`/member/documents/${chatPartner?.id}`);
    } else {
      history.push(`/documents`);
    }
  };

  const createChat = () => {
    setIsCreatingChat(true);
  };

  const handleChatCreate = (chatId: string) => {
    setSelectedChat({ id: chatId });
    setIsCreatingChat(false);
  };

  const groupedMessages = groupMessagesByDate(messages);

  const cancelRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      setAudioBlob(null);
      setIsUploading(false);
      setIsRecording(false);
      setRecordingDuration(0);
      try {
        // @ts-ignore
        mediaRecorderRef.current.removeEventListener('dataavailable', handleDataAvailable);
        // @ts-ignore
        mediaRecorderRef.current.stop();
        // @ts-ignore
        const tracks = mediaRecorderRef.current.stream.getTracks();
        tracks?.forEach((track: any) => track.stop());
        if (recordingTimerRef.current) {
          clearInterval(recordingTimerRef.current);
        }
        audioChunksRef.current = [];
        // @ts-ignore
        mediaRecorderRef.current = null;
      } catch (error) {
        console.error('Error canceling recording:', error);
        setIsRecording(false);
      }
    }
  };

  const deleteMessage = async (messageId: string) => {
    try {
      await db.collection(`tenants/${tenant}/chats/${selectedChat?.id}/messages`).doc(messageId).delete();

      const message = messages.find(m => m.id === messageId);
      if (message?.fileUrl) {
        const fileRef = storage.refFromURL(message.fileUrl);
        await fileRef.delete();
      }
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  if (userData?.role === ROLE_MEMBER && userData?.shareChats === undefined) {
    return <UserConsentForChat />;
  }

  if (isCreatingChat) {
    return <CreateChat onClose={() => setIsCreatingChat(false)} onChatCreate={handleChatCreate} />;
  }

  return (
    <TransitionContainer isShown className="h-full">
      <div
        className={`flex items-center pt-60 pb-20 desktop:pb-0 font-light justify-between desktop:justify-start ${
          !selectedChat ? 'block' : 'hidden'
        } desktop:flex`}
      >
        <Headline level={1}>Chat</Headline>
        <Button className="desktop:ml-100" onClick={createChat}>
          Neuer Chat
        </Button>
      </div>

      <div className="flex desktop:flex-row desktop:pt-40 desktop:h-80v">
        <div
          className={`w-full desktop:w-1/3 desktop:border-r desktop:border-secondaryBgColor ${
            !selectedChat ? 'block' : 'hidden'
          } desktop:block`}
        >
          <ChatSidebar onChatSelect={handleChatSelect} />
        </div>
        {selectedChat && (
          <div className="flex flex-col flex-grow pt-100 mb-80 desktop:pt-0 desktop:pb-0">
            <div className="flex flex-col pt-60 desktop:pt-0 font-light fixed top-0 left-0 right-0 bottom-0 bg-bgColor h-120 px-4 z-30 desktop:static">
              <div className="flex flex-col gap-20">
                <div className="flex justify-between items-center mb-20">
                  <Headline
                    level={3}
                    displayBackBtn={!!selectedChat}
                    goBack={() => {
                      setSelectedChat(null);
                    }}
                    className="truncate"
                  >
                    {selectedChat ? chatPartner?.name : ''}
                  </Headline>
                  {!!selectedChat && (
                    <Button onClick={handleOpenDocuments} buttonStyle="dark">
                      Dokumente
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <div className="overflow-y-auto flex-grow desktop:px-4">
              {Object.entries(groupedMessages).length === 0 && (
                <div className="text-textColor opacity-50 italic h-200" />
              )}
              {Object.entries(groupedMessages).map(([date, chatMessages]) => (
                <div key={date}>
                  <div className="text-center my-4">
                    <span className="text-textColor opacity-50 px-2 py-1 text-sm">{date}</span>
                  </div>
                  {chatMessages.map(renderMessage)}
                </div>
              ))}
              {isUploading && (
                <div className="text-center my-4">
                  <span className="text-textColor opacity-50 px-2 py-1 text-sm">Datei wird hochgeladen...</span>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
            <div className="border-t p-4 pb-30 fixed bottom-0 left-0 right-0 bg-bgColor z-30 desktop:static">
              <div className="flex items-center space-x-2">
                {!isRecording && (
                  <>
                    <button
                      onClick={() => fileInputRef.current && fileInputRef.current.click()}
                      className="p-2 rounded-full text-textColor opacity-50 hover:bg-secondaryBgColor focus:outline-none"
                      type="button"
                      disabled={isUploading || selectedChat?.activeTab === 'public'}
                    >
                      <PaperClipIcon className="h-20 w-20" />
                      <input
                        type="file"
                        ref={fileInputRef}
                        className="hidden"
                        onChange={handleFileUpload}
                        accept="image/*,audio/*,video/*,application/pdf"
                      />
                    </button>
                    <textarea
                      ref={textareaRef}
                      rows={1}
                      value={input}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                      placeholder="Nachricht eingeben..."
                      className={classNames(
                        'flex-grow p-2 border text-black focus:outline-none resize-none overflow-hidden',
                        isTextareaExpanded ? 'rounded-lg' : 'rounded-full'
                      )}
                      disabled={isUploading || selectedChat?.activeTab === 'public'}
                    />
                  </>
                )}
                {!isRecording ? (
                  <button
                    onClick={startRecording}
                    className="p-2 rounded-full text-textColor opacity-50 hover:bg-secondaryBgColor focus:outline-none"
                    type="button"
                    disabled={isUploading || selectedChat?.activeTab === 'public'}
                  >
                    <MicrophoneIcon className="h-20 w-20" />
                  </button>
                ) : (
                  <div className="flex items-center flex-grow gap-2">
                    <button
                      onClick={cancelRecording}
                      className="p-2 rounded-full text-red-500 hover:bg-secondaryBgColor focus:outline-none"
                      type="button"
                    >
                      <TrashIcon className="h-20 w-20" />
                    </button>
                    <span className="text-red-500 mr-2">{recordingDuration}s</span>
                  </div>
                )}
                <button
                  onClick={isRecording ? stopRecording : handleSend}
                  className="p-2 rounded-full text-textColor bg-accentColor hover:bg-accentColorDark focus:outline-none"
                  type="button"
                  disabled={isUploading || selectedChat?.activeTab === 'public'}
                >
                  <PaperAirplaneIcon className="h-20 w-20" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {selectedImage && (
        <Modal>
          <div className="relative pt-20">
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute -top-20 -right-20 text-white text-2xl"
              type="button"
            >
              ✕
            </button>
            <img src={selectedImage || ''} alt="Full size" className="max-h-87v" />
          </div>
        </Modal>
      )}
    </TransitionContainer>
  );
}
