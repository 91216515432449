import React, { useState, useEffect, useRef, useContext } from 'react';
import { toast } from 'react-toast';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { useTranslation } from 'react-i18next';
import { scrollToTop } from 'shared/functions/global';
import CustomSelect from 'components/CustomSelect';
import { MainContext } from 'providers/MainProvider';

import { CalendarIcon } from '@heroicons/react/24/outline';
import { AuthContext } from 'providers/AuthProvider';
import RecipeStepSwitch from '../../../Nutrition/components/RecipeStepSwitch';

registerLocale('de', de);

type Props = {
  className?: string;
  currentStep?: any;
  currentStepValue?: string;
  planState?: any;
  planStateValue?: any;
  popupContainerRef?: any;
};

const NewPlanStep2: React.FC<Props> = ({
  currentStep,
  currentStepValue,
  planState,
  planStateValue,
  popupContainerRef,
}) => {
  const [visible, setVisible] = useState('block');
  const [currentName, setCurrentName] = useState(planStateValue?.name);
  const [startDate, setStartDate] = useState(null);
  const [excludeDateArray, setExcludeDateArray] = useState<any>([]);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const { t } = useTranslation();
  const { userPlanList, planList } = useContext(MainContext);
  const moment = extendMoment(Moment);

  const { userData } = useContext(AuthContext);

  const editPlanNameRef = useRef<HTMLDivElement>(null);

  const caloriesValues: any = [
    { value: '1250_1750', label: '1250 - 1750' },
    { value: '2000_2500', label: '2000 - 2500' },
    { value: '2750_3250', label: '2750 - 3250' },
    { value: '3500_4000', label: '3500 - 4000' },
  ];

  const nutritionValues: any = [
    { value: 'flexitarian', label: 'Flexibel' },
    { value: 'vegetarian', label: 'Vegetarisch' },
    { value: 'vegan', label: 'Vegan' },
    { value: 'ketogen', label: 'Ketogen' },
  ];

  const [currentCalories, setCurrentCalories] = useState('1250_1750');
  const [currentNutrition, setCurrentNutrition] = useState('flexitarian');

  const updateCalories = (value: any) => {
    setCurrentCalories(value.value);
  };

  const updateNutrition = (value: any) => {
    setCurrentNutrition(value.value);
  };

  function jumpToNextStep() {
    let isPlanValid = true;
    let isPlanNameValid = true;

    if (currentName === '' || currentName === undefined) {
      isPlanNameValid = false;
    }

    if (startDate === null || endDate === null) {
      isPlanValid = false;
    }

    if (planStateValue?.planType !== undefined) {
      userPlanList?.forEach(function (key) {
        const thisCheckPlanStartDate = moment.unix(key.startDate.seconds).utc().format('DD-MM-YY');
        const thisCheckPlanEndDate = moment.unix(key.endDate.seconds).utc().format('DD-MM-YY');

        const thisConvertedPlanStartDate = moment(thisCheckPlanStartDate, 'DD-MM-YY');
        const thisConvertedPlanEndDate = moment(thisCheckPlanEndDate, 'DD-MM-YY');

        const thisUserStartDate = moment(startDate).format('YYYY-MM-DD');
        const thisUserEndDate = moment(endDate).format('YYYY-MM-DD');

        const thisPlanRange = moment.range(thisConvertedPlanStartDate, thisConvertedPlanEndDate);
        const thisUserRange = moment.range(moment(thisUserStartDate), moment(thisUserEndDate));

        if (thisUserRange.overlaps(thisPlanRange, { adjacent: true }) === true) {
          isPlanValid = false;
        }
      });
    }

    if (isPlanValid && isPlanNameValid) {
      const newColumns = {
        ...planStateValue,
        name: currentName,
        startDate: {
          seconds: moment(startDate).subtract(-1, 'days').unix(),
        },
        endDate: {
          seconds: moment(endDate).subtract(-1, 'days').unix(),
        },
      };

      const dayEntriesLength =
        moment(endDate).subtract(-1, 'days').diff(moment(startDate).subtract(-1, 'days'), 'days') + 1;

      if (dayEntriesLength > 14 && newColumns.planType !== 'scratch') {
        toast.error('Du kannst maximal 14 Tage als Laufzeit auswählen!');
      } else {
        if (userData?.role === 1) {
          const newColumnsAdmin = {
            ...newColumns,
            examplePlanNutrition: currentNutrition,
            examplePlanCalories: currentCalories,
          };

          planState(newColumnsAdmin);
        } else {
          planState(newColumns);
        }

        setVisible('hidden');
        currentStep('3');
      }
    } else if (isPlanValid && !isPlanNameValid) {
      toast.warn(t('Bitte vergebe noch einen Namen für deinen Plan!'));
    } else {
      toast.error('In diesem Zeitraum existiert schon ein Plan. Bitte wähle ein anderes Datum!');
    }
  }

  function returnToPreviousStep() {
    setVisible('hidden');
    currentStep('1');
  }

  useEffect(() => {
    if (currentStepValue !== '2') {
      setVisible('hidden');
    }

    if (editPlanNameRef.current && visible === 'block') {
      editPlanNameRef.current.focus();
      scrollToTop(popupContainerRef);
    }

    const excludeDatesStaticArray = [] as any;

    if (userData?.role === 1) {
      planList?.forEach(key => {
        key.dayEntries?.forEach(day => {
          excludeDatesStaticArray.push(
            moment
              .unix(day.date.seconds)
              .utc()
              .subtract(+1, 'days')
              .toDate()
          );
        });
      });
    } else {
      userPlanList?.forEach(key => {
        key.dayEntries?.forEach(day => {
          excludeDatesStaticArray.push(
            moment
              .unix(day.date.seconds)
              .utc()
              .subtract(+1, 'days')
              .toDate()
          );
        });
      });
    }

    setExcludeDateArray(excludeDatesStaticArray);
  }, [currentStepValue, planStateValue, visible, popupContainerRef, userPlanList, planList]);

  const changeName = (event: any) => {
    const thisCurrentName = event.currentTarget.textContent;

    setCurrentName(thisCurrentName);
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const CustomInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  return (
    <>
      <div className={visible}>
        <div className="px-20">
          <div className="pt-20 font-light text-base">
            Bitte trage hier die allgemeinen Daten für deinen neuen Plan ein!
          </div>
          <div>
            <div className="pt-20 font-extralight pb-1">Gib deinem Plan einen Namen:</div>
            <div className="flex">
              <div className="w-3/4">
                <div className="font-light left text-xs border-opacity-30 w-full">
                  <span
                    className="appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                    role="textbox"
                    contentEditable
                    onFocus={setBodyClassForMobileSafari}
                    onBlur={removeBodyClassForMobileSafari}
                    suppressContentEditableWarning
                    onInput={e => changeName(e)}
                    ref={editPlanNameRef}
                  >
                    {planStateValue?.name}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-100">
            <div className="pt-20 font-extralight">Wie lange soll dein Plan laufen?</div>
            <div className="font-extralight pb-1">Gib hier bitte einen Zeitraum ein:</div>

            <div className="flex">
              <div className="w-3/4 font-light left text-xs border-opacity-30">
                <DatePicker
                  className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                  selected={startDate}
                  onChange={onChange}
                  locale="de"
                  startDate={startDate}
                  endDate={endDate}
                  excludeDates={excludeDateArray}
                  selectsRange
                  dateFormat="dd.MM.yyyy"
                  withPortal
                  customInput={React.createElement(React.forwardRef(CustomInput))}
                  calendarStartDay={1}
                  onFocus={e => e.target.blur()}
                />
              </div>
            </div>
            {userData?.role === 1 && (
              <div className="pt-20">
                <div className="font-bold">Ordner für Beispielpläne</div>
                <div className="pt-10">Ernährungsform:</div>
                <div className="pb-20">
                  <CustomSelect
                    name="calories"
                    dropDownOption={nutritionValues}
                    defaultValue={nutritionValues[0]}
                    onChange={updateNutrition}
                  />
                </div>
                <div className="pt-10">Kalorien (kcal):</div>
                <div className="pb-80">
                  <CustomSelect
                    name="calories"
                    dropDownOption={caloriesValues}
                    defaultValue={caloriesValues[0]}
                    onChange={updateCalories}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <RecipeStepSwitch
        returnFunction={returnToPreviousStep}
        nextFunction={jumpToNextStep}
        currentStepValue="2"
        totalSteps={planStateValue?.planType === 'alghorithmus' ? 6 : 5}
      />
    </>
  );
};

export default NewPlanStep2;
