import React, { useContext, useEffect, useState } from 'react';
import * as ScanditSDK from 'scandit-sdk';
import { MainContext } from 'providers/MainProvider';
import { AuthContext } from 'providers/AuthProvider';
import { toast } from 'react-toast';
import axios from 'axios';
import { configure, BarcodePicker as ScanditSDKBarcodePicker } from 'scandit-sdk';

type HeaderProps = {
  visible?: boolean;
  playSoundOnScan?: boolean;
  vibrateOnScan?: boolean;
  scanningPaused?: boolean;
  guiStyle?: string;
  videoFit?: string;
  scanSettings?: object;
  enableCameraSwitcher?: boolean;
  enableTorchToggle?: boolean;
  enableTapToFocus?: boolean;
  enablePinchToZoom?: boolean;
  accessCamera?: boolean;
  camera?: object;
  cameraSettings?: object;
  targetScanningFPS?: number;
  onScan?: any;
  onError?: any;
  setVisible?: any;
  setContentVisible?: any;
  setFoodId: Function;
  visiblePopup: boolean;
};

const BarcodePicker: React.FC<HeaderProps> = ({
  visible,
  onScan,
  vibrateOnScan,
  setVisible,
  setContentVisible,
  setFoodId,
  visiblePopup,
}) => {
  const { accessTokenString } = useContext(MainContext);
  const [currentBarCodePicker, setCurrentBarCodePicker] = useState<ScanditSDK.BarcodePicker>();
  const { userData } = useContext(AuthContext);

  // Configure the library and activate it with a license key
  const configurationPromise = configure(
    'Abq116KBNt2RB3HsjUXZQfFAYccUAmTXswVaFltQQFvIO2bJTmLAP2NXeI8yXpli+3Vk9C9HzYhGUyhM4Vq6WLNrquhrYYZUk1GPXopWNxTzfhiDDnt4ygYl3gLZThWDKXRfTPFFyVdAa4vfUnPjDShIjqgVdyQTnVmSgjx5NRB6L3gwKn/RItNtgrEsf9DPOySSfRBC4vUBYYEKhlGMNYt2Kbivc84xWld40EFzGTi9NQH953nz8WxScCjlRsfBcHH4MqosYD/tYxxUQXJP8NMke+GhVOydJkcxEpw07WDxfE8LSSvBK1t4xaD4cKHKs1bxnqFC0ClvKy6tK07tms9HdMgsbhTVIFIshikeRT3TMFMBBnvbi55/VsgBWspI40ESLkN5DoPGPd4HfzZEGd5A4QzHYyUNvkBHo+hRNLYRWpcxtUMNwxx3lxC+XB4/wnzMlBE1aIamTINTs2gCKgNs+L81bTkAZBUMNR5rldtJONSYC3MlA5hRO0BvblIKJXkQZfB6Vh5xZ5KtA3VwFvtXOLnBMRmLWExDh9xvofmJHdentEvH+PpVQzRddv3EpGTGe1twUu0ZblO+7FHtMldXqZPrP8Ml0TWqV54/oaVwZsWeUhiawsw3d9ikcwtpiUwLHetfcldNc55kvX/wCetzfcgeQAAn/lyUikFl7fSVTd39lUE99GBNOypsVE3yG3EtW6hGCKIFVfc4yRx8p64zadhoJ2g7ZTvlO1IQ4WVzEGGNQo+fk1BUU+VzdmG2E1NVpwwnP7gcOKqEAA1lv2Yu8tAEG6wvEDDABNjFDqFp6XCj1q9mxfhl897pzNf+nJ/m6Z1GB2vff04I8B0xnq1BZlUUeMTBq1jgAVpyVvjZuQYul6DtFSAP48q3fg9wYcpCfTCtFoKFIuQQtyCruxnww7E3+FVXkz9NUH90Kc2+RN4614piuedICyeIxsWhhTJ59UxZU5kdhPa5dmhlEPXlEjlAZVzj81+++DMw4KeY9eHuJuuEoCDh42aFzgxGoTrSuEg00jFCJ4+MxfzQ2ye6NeW1phxFW9pikQa9c2FhiLJDTg3VxCGONe+EbKyrcF+9pZKvRaDvF+JkZpEOjidCDfZycBk/hCHwDXNoFtZDITD7bP8lSOCJmgg9M05QwjXQq7p6b2ZM3VNYBEfqgoL+T4QztnX8EMwS+/2Y8omHJQTCoGOpg14dZKsPUkexDqdDCJV3E/97k29/vEer/XiQXucO/REgMQAKKgDnTMVLiY+I676L3lXY3oRCCgLj1TudAZNT/wZ3g39HkvXjpUhp8aukLlCxap66RFVsXWl9ZHSrL4+Yr//9fJxsAteZFUvvggKg+EUz+pQEgCEi+NL+Mz90a8yB2J1bfP2uuhKfMuI8SomK9mqaoCc6d/+zebUxoW3+86NyXUT50vD0PC3S',
    {
      engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/',
    }
  ).catch(error => {
    console.log(error);
  });

  const scanSettings = new ScanditSDK.ScanSettings({
    enabledSymbologies: [ScanditSDK.Barcode.Symbology.EAN8, ScanditSDK.Barcode.Symbology.EAN13],
    codeDuplicateFilter: -1,
  });

  useEffect(() => {
    const portalDiv = document.getElementById('barcodeContainer')!;

    let scanditBarcodePicker!: ScanditSDK.BarcodePicker;

    configurationPromise.then(() => {
      ScanditSDKBarcodePicker.create(portalDiv, {
        playSoundOnScan: false,
        vibrateOnScan: false,
        accessCamera: false,
        visible,
      }).then(barcodePicker => {
        barcodePicker.applyScanSettings(scanSettings);
        setCurrentBarCodePicker(barcodePicker);
        barcodePicker.on('scan', scanResult => {
          let thisBarCode = '';
          const getBarCode = scanResult.barcodes.reduce((string, barcode) => {
            thisBarCode = barcode.data;
            return `${string}${barcode.data}\n\n`;
          }, '');

          if (visible === true && thisBarCode !== '0') {
            if (accessTokenString.length === 0) {
              try {
                axios({
                  url: `${process.env.REACT_APP_API_URL}/generateToken`,
                  method: 'post',
                  headers: {
                    'content-type': 'application/json',
                    Accept: 'application/json',
                  },
                }).then(response => {
                  const thisAccessTokenString = response.data.access_token;

                  try {
                    axios({
                      url: `${process.env.REACT_APP_API_URL}/getBarcode`,
                      method: 'post',
                      data: {
                        token: thisAccessTokenString,
                        barcode: thisBarCode,
                        language: userData?.country ?? 'DE',
                      },
                      headers: {
                        'content-type': 'application/json',
                        Accept: 'application/json',
                      },
                    }).then(response2 => {
                      if (response2.data.food_id !== undefined) {
                        setContentVisible(false);
                        setFoodId(response2.data.food_id.value);
                        barcodePicker.pauseScanning(true);
                      } else {
                        setVisible(false);
                        setContentVisible(false);
                        toast.warn('Es wurde leider kein Lebensmittel zu diesem Barcode gefunden!');
                        barcodePicker.pauseScanning(true);
                      }
                    });
                  } catch (err) {
                    console.log('error');
                  }
                });
              } catch (err) {
                console.log('error');
              }
            } else {
              try {
                axios({
                  url: `${process.env.REACT_APP_API_URL}/getBarcode`,
                  method: 'post',
                  data: {
                    token: accessTokenString,
                    barcode: thisBarCode,
                    language: userData?.country ?? 'DE',
                  },
                  headers: {
                    'content-type': 'application/json',
                    Accept: 'application/json',
                  },
                }).then(response => {
                  if (response.data.food_id !== undefined) {
                    setContentVisible(false);
                    setFoodId(response.data.food_id.value);
                    barcodePicker.pauseScanning(true);
                  } else {
                    setVisible(false);
                    setContentVisible(false);
                    toast.warn('Es wurde leider kein Lebensmittel zu diesem Barcode gefunden!');
                    barcodePicker.pauseScanning(true);
                  }
                });
              } catch (err) {
                console.log('error');
              }
            }
          }
        });
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (visiblePopup === true) {
      currentBarCodePicker?.accessCamera();
      currentBarCodePicker?.resumeScanning();
    } else {
      currentBarCodePicker?.pauseScanning(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiblePopup]);

  return <div />;
};

export default BarcodePicker;
